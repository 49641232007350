import { motion } from "framer-motion";
import '../../src/index.css';

const ScrollingDiscountBar = () => {
  return (
    <section className="scrolling-bar-container">
      <motion.div
        initial={{ x: 0 }}
        animate={{ x: "-50%" }}
        transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
        className="scrolling-bar-text"
      >
        {/* Duplicate the text twice to create a continuous loop */}
        <ScrollingText />
        <ScrollingText />
      </motion.div>
    </section>
  );
};

const ScrollingText = () => (
  <>
    <span className="scrolling-bar-item">DISCOUNT CODE: ALPHA22</span>
    <span className="scrolling-bar-item">GET 22.5% OFF!</span>
    <span className="scrolling-bar-item">DISCOUNT CODE: ALPHA22</span>
    <span className="scrolling-bar-item">GET 22.5% OFF!</span>
    <span className="scrolling-bar-item">DISCOUNT CODE: ALPHA22</span>
    <span className="scrolling-bar-item">GET 22.5% OFF!</span>
    <span className="scrolling-bar-item">DISCOUNT CODE: ALPHA22</span>
    <span className="scrolling-bar-item">GET 22.5% OFF!</span>
    <span className="scrolling-bar-item">DISCOUNT CODE: ALPHA22</span>
    <span className="scrolling-bar-item">GET 22.5% OFF!</span>
    <span className="scrolling-bar-item">DISCOUNT CODE: ALPHA22</span>
  </>
);

export default ScrollingDiscountBar;