// import React, { useState, useEffect, useRef } from 'react';
// import '../../src/index.css';

// const TradingObjectivesSection = () => {
//   const [selectedBalance, setSelectedBalance] = useState('R7,500');
//   const [selectedChallenge, setSelectedChallenge] = useState('1 Step');
//   const sectionRef = useRef(null);

//   useEffect(() => {
//     const observer = new IntersectionObserver((entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           entry.target.classList.add('animate');
//         }
//       });
//     }, { threshold: 0.1 });
  
//     const elementsToAnimate = document.querySelectorAll('.objectives-title, .button-container, .balance-buttons-container, .div-table-container, .buy-challenge-button');
//     elementsToAnimate.forEach((element) => {
//       observer.observe(element);
//     });
  
//     return () => {
//       elementsToAnimate.forEach((element) => {
//         observer.unobserve(element);
//       });
//     };
//   }, []);

//   const formatAmount = (amount) => {
//     return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').split('.')[0]; // Remove decimal part
//   };
  
//   const getRefundableFee = () => {
//     const discountRate = 0.225; 
//     const refundableFees = {
//       '1 Step': {
//         'R7,500': 'R309.99',
//         'R12,500': 'R449.99',
//         'R20,000': 'R649.99',
//         'R50,000': 'R1,320',
//         'R100,000': 'R2,200',
//         'R250,000': 'R3,020',
//         'R500,000': 'R3,850',
//         'R1,000,000': 'R6,500',
//         'R2,000,000': 'R11,000',
//       },
//       '2 Step': {
//         'R7,500': 'R279.99',
//         'R12,500': 'R399.99',
//         'R20,000': 'R599.99',
//         'R50,000': 'R1,200',
//         'R100,000': 'R2,000',
//         'R250,000': 'R2,800',
//         'R500,000': 'R3,500',
//         'R1,000,000': 'R6,000',
//         'R2,000,000': 'R10,000',
//       },
//     };

    
//     const originalFee = parseFloat(refundableFees[selectedChallenge][selectedBalance].replace('R', '').replace(',', ''));
//     const discountedFee = (originalFee * (1 - discountRate)).toFixed(2);

//     return {
//       originalFee: refundableFees[selectedChallenge][selectedBalance],
//       discountedFee: `R${discountedFee}`, // Keep it formatted with two decimals
//       discountRate: (discountRate * 100).toFixed(2), // For displaying as a badge with one decimal place
//     };
//   };

//   const getTableData = () => {
//     const data = {
//       '1 Step': {
//         'Verification Step': [
//           ['Profit Target', '8%'],
//           ['Daily Drawdown', '4%'],
//           ['Overall Drawdown', '6%'],
//           ['Minimum Daily Profit Days', '-'],
//           ['Maximum Leverage', '1:100'],
//           ['Refundable Fee', getRefundableFee().originalFee],
//         ],
//         'Funded Account': [
//           ['Daily Drawdown', '4%'],
//           ['Overall Drawdown', '6%'],
//           ['Minimum Daily Profit Days', '5x Trading days @ 0.5% profit'],
//           ['Withdrawal Period', '14 Days'],
//           ['Maximum Leverage', '1:100'],
//           ['Initial Profit Split', '70%'],
//         ],
//       },
//       '2 Step': {
//         'Evaluation Step': [
//           ['Profit Target', '8%'],
//           ['Daily Drawdown', '4%'],
//           ['Overall Drawdown', '8%'],
//           ['Minimum Daily Profit Days', '-'],
//           ['Maximum Leverage', '1:100'],
//           ['Refundable Fee', getRefundableFee().originalFee],
//         ],
//         'Verification Step': [
//           ['Profit Target', '5%'],
//           ['Daily Drawdown', '4%'],
//           ['Overall Drawdown', '8%'],
//           ['Minimum Daily Profit Days', '-'],
//           ['Maximum Leverage', '1:100'],
//         ],
//         'Funded Account': [
//           ['Daily Drawdown', '4%'],
//           ['Overall Drawdown', '8%'],
//           ['Minimum Daily Profit Days', '4x Trading days @ 0.5% profit'],
//           ['Withdrawal Period', '14 Days'],
//           ['Maximum Leverage', '1:100'],
//           ['Initial Profit Split', '85%'],
//         ],
//       },
//     };

//     return data[selectedChallenge];
//   };

//   const renderTableRows = () => {
//     const tableData = getTableData();
//     const balance = parseInt(selectedBalance.replace(/R|,/g, ''), 10);

//     const criteriaNames = [
//       'Profit Target',
//       'Daily Drawdown',
//       'Overall Drawdown',
//       'Minimum Daily Profit Days',
//       'Maximum Leverage',
//       'Withdrawal Period',
//       'Initial Profit Split',
//       'Refundable Fee',
//     ];

//     const noCalculationCriteria = [
//       'Minimum Daily Profit Days',
//       'Maximum Leverage',
//       'Withdrawal Period',
//       'Initial Profit Split',
//       'Refundable Fee',
//     ];

//     return criteriaNames.map((criteriaName) => {
//       const row = [
//         <div key={criteriaName} className="div-table-cell">
//           {criteriaName.replace(/([A-Z])/g, ' $1')}
//         </div>,
//       ];
//       Object.keys(tableData).forEach((phase) => {
//         const criteria = tableData[phase].find((c) => c[0] === criteriaName);
//         if (criteria) {
//           let value = criteria[1];
//           if (criteriaName === 'Refundable Fee') {
//             const { originalFee, discountedFee, discountRate } = getRefundableFee();
//             value = (
//               <span>
//                 <span style={{ textDecoration: 'line-through', color: 'grey' }}>{originalFee}</span>{' '}
//                 <span style={{ color: 'green' }}>{discountedFee}</span>{' '}
//                 <span className="discount-badge">{`${discountRate}% OFF`}</span>
//               </span>
//             );
//           } else if (!noCalculationCriteria.includes(criteriaName) && !isNaN(parseFloat(value))) {
//             const amount = balance * parseFloat(value) / 100;
//             value = `R${formatAmount(amount)} (${criteria[1]})`;
//           }
//           row.push(
//             <div key={phase} className="div-table-cell">
//               {value}
//             </div>
//           );
//         } else {
//           row.push(
//             <div key={phase} className="div-table-cell">
//               -
//             </div>
//           );
//         }
//       });
//       return (
//         <div key={criteriaName} className="div-table-row">
//           {row}
//         </div>
//       );
//     });
//   };

//   return (
//     <section className="trading-objectives-section" ref={sectionRef}>
//       <div className="trading-objectives-card">
//         <h2 className="objectives-title">Trading Objectives</h2>
//         <div className="button-container">
//           <button
//             className={`challenge-button ${selectedChallenge === '1 Step' ? 'selected' : ''}`}
//             onClick={() => setSelectedChallenge('1 Step')}
//           >
//             1-Step Alpha Challenge
//           </button>
//           <button
//             className={`challenge-button ${selectedChallenge === '2 Step' ? 'selected' : ''}`}
//             onClick={() => setSelectedChallenge('2 Step')}
//           >
//             2-Step Alpha Challenge
//           </button>
//         </div>
//         <div className="balance-buttons-container">
//             <div className="balance-buttons-row">
//               {['R7,500', 'R12,500', 'R20,000', 'R50,000'].map((balance) => (
//                 <button
//                   key={balance}
//                   className={`balance-button ${selectedBalance === balance ? 'selected' : ''}`}
//                   onClick={() => setSelectedBalance(balance)}
//                 >
//                   {balance}
//                 </button>
//               ))}
//             </div>
//             <div className="balance-buttons-row">
//               {['R100,000', 'R250,000', 'R500,000', 'R1,000,000', 'R2,000,000'].map((balance) => (
//                 <button
//                   key={balance}
//                   className={`balance-button ${selectedBalance === balance ? 'selected' : ''}`}
//                   onClick={() => setSelectedBalance(balance)}
//                 >
//                   {balance}
//                 </button>
//               ))}
//             </div>
//         </div>
//         <div className="div-table-container">
//           <div className="div-table-row">
//             <div className="div-table-header">{selectedBalance}</div>
//             {selectedChallenge === '2 Step' && <div className="div-table-header">Evaluation Step</div>}
//             <div className="div-table-header">Verification Step</div>
//             <div className="div-table-header">Alpha Funded Account</div>
//           </div>
//           {renderTableRows()}
//         </div>
//         <div className="buy-challenge-button animate">
//         <button onClick={() => window.open('https://client.alphafunder.io/sign-in', '_blank', 'noopener,noreferrer')}>Start Challenge Now!</button>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default TradingObjectivesSection;

import React, { useState, useEffect, useRef } from 'react';
import '../../src/index.css';

const TradingObjectivesSection = () => {
  const [selectedBalance, setSelectedBalance] = useState('$5,000');
  const [selectedChallenge, setSelectedChallenge] = useState('1 Step');
  const sectionRef = useRef(null);

  const zarValues = {
    '$5,000': 'R88,500',
    '$10,000': 'R177,500',
    '$25,000': 'R443,000',
    '$50,000': 'R886,500',
    '$100,000': 'R1,773,000',
    '$500': 'R8,850',
    '$1,000': 'R17,750',
    '$2,500': 'R44,350',
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        }
      });
    }, { threshold: 0.1 });

    const elementsToAnimate = document.querySelectorAll('.objectives-title, .button-container, .balance-buttons-container, .div-table-container, .buy-challenge-button');
    elementsToAnimate.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elementsToAnimate.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  const formatAmount = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').split('.')[0];
  };

  const getRefundableFee = () => {
    const discountRate = 0.225;
    const refundableFees = {
      '1 Step': {
        '$500': { usd: '$21.99', zar: 'R370' },
        '$1,000': { usd: '$32.99', zar: 'R564' },
        '$2,500': { usd: '$64.99', zar: 'R740' },
        '$5,000': { usd: '$109.99', zar: 'R1,180' },
        '$10,000': { usd: '$119.99', zar: 'R2,060' },
        '$25,000': { usd: '$189.99', zar: 'R4,350' },
        '$50,000': { usd: '$327.99', zar: 'R6,150' },
        '$100,000': { usd: '$547.99', zar: 'R9,640' },
      },
      '2 Step': {
        '$500': { usd: '$18.99', zar: 'R335' },
        '$1,000': { usd: '$29.99', zar: 'R510' },
        '$2,500': { usd: '$55.99', zar: 'R670' },
        '$5,000': { usd: '$99.99', zar: 'R1,775' },
        '$10,000': { usd: '$109.99', zar: 'R1,949' },
        '$25,000': { usd: '$174.99', zar: 'R4,000' },
        '$50,000': { usd: '$297.99', zar: 'R5,410' },
        '$100,000': { usd: '$497.99', zar: 'R9,290' },
      },
    };

    const { usd, zar } = refundableFees[selectedChallenge][selectedBalance];
    const originalFeeUSD = parseFloat(usd.replace('$', ''));
    const discountedFeeUSD = (originalFeeUSD * (1 - discountRate)).toFixed(2);

    return {
      originalFee: `${usd} (${zar})`,
      discountedFee: `$${discountedFeeUSD}`,
      discountRate: (discountRate * 100).toFixed(2),
    };
  };

  const getTableData = () => {
    const data = {
      '1 Step': {
        'Verification Step': [
          ['Profit Target', '8%'],
          ['Daily Drawdown', '4%'],
          ['Overall Drawdown', '6%'],
          ['Minimum Daily Profit Days', '-'],
          ['Maximum Leverage', '1:100'],
          ['Refundable Fee', getRefundableFee().originalFee],
        ],
        'Funded Account': [
          ['Daily Drawdown', '4%'],
          ['Overall Drawdown', '6%'],
          ['Minimum Daily Profit Days', '5x Trading days @ 0.5% profit'],
          ['Withdrawal Period', '14 Days'],
          ['Maximum Leverage', '1:100'],
          ['Initial Profit Split', '70%'],
        ],
      },
      '2 Step': {
        'Evaluation Step': [
          ['Profit Target', '8%'],
          ['Daily Drawdown', '4%'],
          ['Overall Drawdown', '8%'],
          ['Minimum Daily Profit Days', '-'],
          ['Maximum Leverage', '1:100'],
          ['Refundable Fee', getRefundableFee().originalFee],
        ],
        'Verification Step': [
          ['Profit Target', '5%'],
          ['Daily Drawdown', '4%'],
          ['Overall Drawdown', '8%'],
          ['Minimum Daily Profit Days', '-'],
          ['Maximum Leverage', '1:100'],
        ],
        'Funded Account': [
          ['Daily Drawdown', '4%'],
          ['Overall Drawdown', '8%'],
          ['Minimum Daily Profit Days', '4x Trading days @ 0.5% profit'],
          ['Withdrawal Period', '14 Days'],
          ['Maximum Leverage', '1:100'],
          ['Initial Profit Split', '85%'],
        ],
      },
    };

    return data[selectedChallenge];
  };

  const renderTableRows = () => {
    const tableData = getTableData();
    const balance = parseInt(selectedBalance.replace(/[^0-9.-]+/g, ''), 10);

    const criteriaNames = [
      'Profit Target',
      'Daily Drawdown',
      'Overall Drawdown',
      'Minimum Daily Profit Days',
      'Maximum Leverage',
      'Withdrawal Period',
      'Initial Profit Split',
      'Refundable Fee',
    ];

    return criteriaNames.map((criteriaName) => {
      const row = [
        <div key={criteriaName} className="div-table-cell">
          {criteriaName.replace(/([A-Z])/g, ' $1')}
        </div>,
      ];
      Object.keys(tableData).forEach((phase) => {
        const criteria = tableData[phase].find((c) => c[0] === criteriaName);
        if (criteria) {
          let value = criteria[1];
          if (criteriaName === 'Refundable Fee') {
            const { originalFee, discountedFee, discountRate } = getRefundableFee();
            value = (
              <span>
                <span style={{ textDecoration: 'line-through', color: 'grey' }}>{originalFee}</span>{' '}
                <span style={{ color: 'green' }}>{discountedFee}</span>{' '}
                <span className="discount-badge">{`${discountRate}% OFF`}</span>
              </span>
            );
          } else if (!isNaN(parseFloat(value))) {
            const amount = balance * parseFloat(value) / 100;
            value = `$${formatAmount(amount)} (${criteria[1]})`;
          }
          row.push(
            <div key={phase} className="div-table-cell">
              {value}
            </div>
          );
        } else {
          row.push(
            <div key={phase} className="div-table-cell">
              -
            </div>
          );
        }
      });
      return (
        <div key={criteriaName} className="div-table-row">
          {row}
        </div>
      );
    });
  };

  return (
    <section className="trading-objectives-section" ref={sectionRef}>
      <div className="trading-objectives-card">
        <h2 className="objectives-title">Trading Objectives</h2>
        <div className="button-container">
          <button
            className={`challenge-button ${selectedChallenge === '1 Step' ? 'selected' : ''}`}
            onClick={() => setSelectedChallenge('1 Step')}
          >
            1-Step Alpha Challenge
          </button>
          <button
            className={`challenge-button ${selectedChallenge === '2 Step' ? 'selected' : ''}`}
            onClick={() => setSelectedChallenge('2 Step')}
          >
            2-Step Alpha Challenge
          </button>
        </div>
        <div className="balance-buttons-container">
        <div className="balance-buttons-row">
  {['$5,000', '$10,000', '$25,000', '$50,000', '$100,000','$500', '$1,000', '$2,500'].map((balance) => (
    <button
    key={balance}
    className={`balance-button ${selectedBalance === balance ? 'selected' : ''} ${
      (balance === '$5,000' || balance === '$10,000') ? 'fluorescent-green' : ''
    }`}
    onClick={() => setSelectedBalance(balance)}
  >
    <div>{balance}</div>
    <div>({zarValues[balance]})</div> {/* ZAR value below USD */}
  </button>
  ))}
</div>

        </div>
        <div className="div-table-container">
        <div className="div-table-row">
  <div className={`div-table-header ${selectedBalance === '$5,000' || selectedBalance === '$10,000' ? 'table-header-green' : ''}`}>
    {selectedBalance}
  </div>
  {selectedChallenge === '2 Step' && (
    <div className={`div-table-header ${selectedBalance === '$5,000' || selectedBalance === '$10,000' ? 'table-header-green' : ''}`}>
      Evaluation Step
    </div>
  )}
  <div className={`div-table-header ${selectedBalance === '$5,000' || selectedBalance === '$10,000' ? 'table-header-green' : ''}`}>
    Verification Step
  </div>
  <div className={`div-table-header ${selectedBalance === '$5,000' || selectedBalance === '$10,000' ? 'table-header-green' : ''}`}>
    Funded Account
  </div>
</div>

          {renderTableRows()}
        </div>
      </div>
    </section>
  );
};

export default TradingObjectivesSection;




